import * as React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { Carousel, UpcomingEvents, PastEvents } from "../components/Events";

const EventsPage = () => {
  return (
    <div>
      <Navbar />
      <Carousel />
      <UpcomingEvents />
      <PastEvents />
      <Footer />
    </div>
  );
};

export default EventsPage;
