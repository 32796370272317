import React from "react";
import Carousel from "./Carousel";
import EventCard from "./EventCard";
import CarouselPlaceholder from "../../images/carousel-placeholder.png";
import SearchBar from "./SearchBar";

const UpcomingEvents: React.FC = () => {
  return (
    <div className="my-10 sm:my-16 max-w-screen-xl w-full mx-auto px-5">
      <SearchBar />

      <div className="grid grid-cols-1 sm:grid-cols-3 gap-10">
        {Array.from({ length: 10 }).map((_, i) => (
          <EventCard
            key={i}
            title="Misinformation Conference, 2021"
            date="13th January, 2022"
            image={CarouselPlaceholder}
          />
        ))}
      </div>
    </div>
  );
};

export default UpcomingEvents;
