import React from "react";
import CarouselPlaceholder from "../../images/carousel-placeholder.png";

const Carousel: React.FC = () => {
  return (
    <div className="w-full my-5 mb-20">
      <div className="text-2xl sm:text-5xl font-extrabold text-mca-grey px-5 sm:px-20">
        Upcoming <span className="text-mca-green">Events</span>
      </div>
      <div className="w-full relative my-5 h-[60vh]">
        <div className="h-full w-full z-10">
          <img
            src={CarouselPlaceholder}
            className="h-full w-full object-cover"
          />
        </div>
        <div className="h-full w-full absolute top-0 left-0 z-20 flex items-stretch justify-between">
          <div className="text-white w-[50px] sm:w-[80px] flex items-center justify-center transition cursor-pointer hover:bg-opacity-20 hover:bg-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 sm:h-8 sm:w-8"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </div>
          <div className="text-white w-[50px] sm:w-[80px] flex items-center justify-center transition cursor-pointer hover:bg-opacity-20 hover:bg-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 sm:h-8 sm:w-8 rotate-180"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className="text-sm sm:text-lg font-extrabold text-mca-grey-2 px-5 sm:px-20 uppercase">
        Misinformation Conference, New Delhi (2021)
      </div>
    </div>
  );
};

export default Carousel;
