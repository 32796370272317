import React from "react";

export default function () {
  return (
    <div className="mb-10 flex justify-center">
      <div className="bg-gray-200 rounded-full flex items-center max-w-[600px] w-full focus-within:ring-[3px] ring-mca-green transition">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4 sm:h-6 sm:w-6 mx-4 mr-3 my-4 text-mca-grey-2"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={3}
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
        <input
          type="text"
          className="flex-1 h-full bg-[transparent] rounded-r-full border-none focus:outline-none active:outline-none focus:shadow-none active:shadow-none m-0 p-0 font-bold placeholder:font-bold text-base sm:text-lg placeholder:text-[#aaa]"
          placeholder="Search conferences by keywords, topics, or location"
        />
      </div>
    </div>
  );
}
